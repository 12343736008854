<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            User Management
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link to="/users" class="btn btn-light font-weight-bolder"
            ><v-icon>mdi-chevron-left</v-icon> Back</router-link
          >
          <!--end::Button-->
        </div>
      </div>
      <div class="card-body">
        <form class="form">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-6">
                <label>{{$t('users.first_name')}}<span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="data.first_name"
                  class="form-control"
                  :class="validation && validation.first_name ? 'is-invalid' : ''"
                  placeholder="Enter first name"
                />
                <span
                  v-if="validation && validation.first_name"
                  class="fv-plugins-message-container invalid-feedback"
                >
                  {{ validation.first_name[0] }}
                </span>
              </div>
              <div class="col-lg-6">
                <label>{{$t('users.last_name')}}<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="
                    validation && validation.last_name ? 'is-invalid' : ''
                  "
                  v-model="data.last_name"
                  placeholder="Enter last name"
                />
                <span
                  v-if="validation && validation.last_name"
                  class="fv-plugins-message-container invalid-feedback"
                >
                  {{ validation.last_name[0] }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-6">
                <label>{{$t('users.email')}}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control"
                    :class="validation && validation.email ? 'is-invalid' : ''"
                    v-model="data.email"
                    placeholder="Enter your email"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="la la-envelope"></i
                    ></span>
                  </div>
                  <span
                    v-if="validation && validation.email"
                    class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.email[0] }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6">
                <label>{{$t('users.user_name')}}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.username"
                    placeholder="Enter your username"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="la la-user-shield"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-6">
                <label>{{$t('users.countries')}}</label>
                <div class="input-group">
                  <select
                    name=""
                    id="countries"
                    v-model="data.country_id"
                    class="form-control"
                  >
                    <option
                      v-for="row in countries"
                      :value="row.id"
                      :key="row.id"
                    >
                      {{ row.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6">
                <label>{{$t('users.cities')}}</label>
                <div class="input-group">
                  <select
                    name=""
                    id="cities"
                    v-model="data.city_id"
                    class="form-control"
                  >
                    <option v-for="row in cities" :value="row.id" :key="row.id">
                      {{ row.name }}
                    </option>
                  </select>
                </div>
                <!-- <span class="form-text text-muted">Please enter your postcode</span> -->
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-6">
                <label>{{$t('users.password')}}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input
                    type="password"
                    class="form-control"
                    :class="
                      validation && validation.password ? 'is-invalid' : ''
                    "
                    v-model="data.password"
                    placeholder="Enter your Password"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="la la-lock"></i
                    ></span>
                  </div>
                  <span
                    v-if="validation && validation.password"
                    class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.password[0] }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6">
                <label
                  >{{$t('users.confirm_password')}}<span class="text-danger"
                    >*</span
                  ></label
                >
                <div class="input-group">
                  <input
                    type="password"
                    class="form-control"
                    :class="
                      validation && validation.password_confirmation
                        ? 'is-invalid'
                        : ''
                    "
                    v-model="data.password_confirmation"
                    placeholder="Enter your confirmation password"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      ><i class="la la-lock"></i
                    ></span>
                  </div>
                  <span
                    v-if="validation && validation.password_confirmation"
                    class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.password_confirmation[0] }}
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-6">
                <label>{{$t('users.status')}}:</label>
                <div class="radio-inline">
                  <label class="radio radio-solid">
                    <!-- <input type="radio" v-model="data.status"/> -->
                    <input
                      name="status"
                      v-model="data.status"
                      type="radio"
                      value="active"
                      :checked="checkedValue == 'active'"
                    />
                    <span></span>
                    {{$t('users.active')}}
                  </label>
                  <label class="radio radio-solid">
                    <input
                      name="status"
                      v-model="data.status"
                      type="radio"
                      value="inactive"
                      :checked="checkedValue == 'inactive'"
                    />
                    <span></span>
                    {{$t('users.inactive')}}
                  </label>
                </div>
                <!-- <span class="form-text text-muted">Please select user group</span> -->
              </div>
              <div class="col-lg-6">
                <div>
                  <label>{{$t('users.roles')}}</label>
                  <multiselect
                    v-model="data.role_id"
                    placeholder="Search roles"
                    label="name"
                    track-by="id"
                    :options="roles"
                    :multiple="true"
                    :taggable="true"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-6">
                <button
                  type="reset"
                  class="btn btn-primary mr-2"
                  @click="save()"
                >
                  Save
                </button>
                <button type="reset" class="btn btn-secondary">Cancel</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-country",
  data() {
    return {
      data: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        country_id: "",
        city_id: "",
        status: "",
        role_id: [],
      },
      checkedValue: "",

      isEditing: false,
      countries: [],
      cities: [],
      roles: [],
      validation: null,
    };
  },
  watch: {
    "data.country_id": function (val) {
      if (val) {
        this.getCities(val).then((cities) => {
          this.cities = cities;
        });
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.getRoleById();
      ApiService.post("users", {
        ...this.data,
      })
        .then(() => {
          this.validation = null;
          // this.$successAlert(response.data.message);
          window.location.href = "/users";
        })
        .catch((error) => {
          this.validation = error.response ? error.response.data.errors : null;
          this.$errorAlert(error);
        });
    },
    getRoleById() {
      let role_ids = [];
       this.data.role_id.filter((role) => {
        role_ids.push(role.id);
      });
      this.data.role_id = role_ids;
    },

    update() {
      ApiService.put(`users/${this.$route.params.id}`, {
        ...this.data,
      })
        .then(() => {
          this.validation = null;
          window.location.href = "/users";
        })
        .catch((error) => {
          this.validation = error.response ? error.response.data.errors : null;
          this.$errorAlert(error);
        });
    },

    async getData() {
      ApiService.get(`users/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
      });
    },

    getCountries() {
      ApiService.get("base/dependency/countries").then((response) => {
        this.countries = response.data.data;
      });
    },
    getRoles() {
      ApiService.get("base/dependency/roles").then((response) => {
          this.roles = response.data.data.map((role) => {
            return {
              id: role.id,
              name: role.name,
            };
          });
      });
    },
   

    getCities(id) {
      ApiService.get(`base/dependency/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users From" }]);
    this.getCountries();
    this.getRoles();
    this.getData();
    this.checkedValue = "active";
  },
};
</script>


